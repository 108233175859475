import { BookOutlined, CreditCardOutlined, PlayCircleFilled, PlayCircleOutlined, PlayCircleTwoTone, PlusOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Typography, Button, Card, Col, Row, Skeleton, List, Divider, Affix, Modal } from 'antd';
import { Image } from "antd";
import { useRouter } from 'next/router';
import { CSSProperties, ChangeEvent, ChangeEventHandler, MutableRefObject, Ref, useEffect, useMemo, useRef, useState } from 'react';
const { Text, Title, Paragraph } = Typography;
import { RocketOutlined, FileTextOutlined, DesktopOutlined, CompassOutlined } from '@ant-design/icons';

const WITH_LANES = true;

// import { LaneDemo } from "chainstream-three.js-demos/src/client/client";
import { useScroll, useSize } from 'ahooks';
import { useResponsive } from "ahooks/lib/useResponsive";
// import { CAMERA_POSITIONS } from 'chainstream-three.js-demos/src/client/scenes/setup';
import { useRandomImage } from '../hooks/useRandomImage';
import Link from 'next/link';
import { ActionButtonsBlock } from '../components/utils/actionButton';
import { IntroNav } from './intro/nav';
import Head from 'next/head';

// const MakeLaneDemo = (laneCanvas: HTMLCanvasElement) => {
//   if (!WITH_LANES)
//     return;
//   // const laneCanvas = typeof window != "undefined" && typeof document != "undefined" ? document.createElement('canvas') : {} as HTMLCanvasElement;
//   // return;
//   const hasDemo = (window as any).hasDemo || null;

//   console.log("Has demo:", hasDemo)

//   if (hasDemo)
//     return hasDemo;

//   const laneDemo = laneCanvas ? LaneDemo({ canvas: laneCanvas, withControls: false }) : {} as ReturnType<typeof LaneDemo>;
//   if (laneCanvas?.style) {
//     laneCanvas.style.position = "fixed"
//     laneCanvas.style.bottom = "0"
//     laneCanvas.style.zIndex = "-1500";
//   };
//   (window as any).hasDemo = { laneDemo, laneCanvas };
//   return { laneDemo, laneCanvas };
// }


const slogan = [
  "guardians of continuity",
  "a bridge over troubled webhooks",
  "excels where webhooks fail",
  "The missing piece in your API puzzle",
  "Your API safety net"
][Math.floor(Math.random() * 3)];


// type ValidCameraPositionName = keyof typeof CAMERA_POSITIONS;

export const Page = () => {
  const router = useRouter();
  const laneContainers = {
    default: useRef<HTMLDivElement>(),
    top: useRef<HTMLDivElement>(),
    front: useRef<HTMLDivElement>(),
    back: useRef<HTMLDivElement>(),
    frontLeft: useRef<HTMLDivElement>(),
  } as Record<any, MutableRefObject<HTMLDivElement | undefined>>;

  const laneCanvasRef = useRef<HTMLCanvasElement>();

  const scroll = useScroll();

  // eslint-disable-next-line
  // const canvasMemo = useMemo(() => {
  //   return laneCanvasRef.current && MakeLaneDemo(laneCanvasRef.current)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [laneCanvasRef.current]);

  // const { laneDemo, laneCanvas } = canvasMemo || {};

  // useEffect(() =>
  //   () => {
  //     laneDemo?.dispose && laneDemo?.dispose();
  //     const context = laneCanvas?.getContext('2d');
  //     context?.clear();
  //     laneCanvas?.parentElement?.removeChild(laneCanvas);
  //     (window as any).hasDemo = false;
  //   },
  //   [laneDemo, laneCanvas, laneCanvasRef]);

  // useEffect(() => {
  //   const views = Object.keys(CAMERA_POSITIONS) as ValidCameraPositionName[];
  //   if (!scroll || views.find((v) => !laneContainers[v] || !laneContainers[v].current))
  //     return;

  //   const lastVisible: ValidCameraPositionName =
  //     views.reduce((prev, curr) => {
  //       const lc = laneContainers[curr];
  //       if (!lc || !lc.current)
  //         return prev;
  //       const sib = lc.current.previousSibling;
  //       const sibIsElement = sib instanceof HTMLElement;
  //       if (scroll.top >= document.body.offsetHeight - window.innerHeight - 100)
  //         return "frontLeft";
  //       return !prev || (lc.current.offsetTop - (sibIsElement ? sib.offsetHeight * 1.1 : 0) < scroll.top) ? curr : prev
  //     }, "default" as ValidCameraPositionName);

  //   laneDemo?.transition && (laneDemo as any).transition(lastVisible);
  //   // eslint-disable-next-line
  // }, [...Object.values(laneContainers || {}).map(lc => lc.current), scroll?.top])

  const imgr = useRandomImage;

  const subBlock = ({ title, text, path, icon }: { title: string, text?: string, path?: string, icon?: JSX.Element }) => {
    if (path)
      return <Link href={path} className='hoverable-landing' style={{ padding: 24, display: "block" }}>
        <Row gutter={18} align={"middle"}>
          <Col key={21} span={4}>
            {icon}
          </Col>
          <Col key={22} span={20}>
            <h3 style={{ margin: 0 }}>{title}</h3>
            {text ? <div className={path ? '' : "landing-block-text"}>{text}</div> : <></>}
          </Col>
        </Row>
      </Link>

    return <><h3>{icon} {title}</h3><div className='landing-block-text'>{text}</div></>
  }

  const resp = useResponsive();

  const Block = (content: { title: string, text?: string, path?: string, icon?: JSX.Element }[], rtl: boolean = false, extra?: React.ReactElement) => {
    const animatedElementRef = useRef<any>();
    const ioRef = useRef<any>();

    useEffect(() => {
      if (!animatedElementRef.current)
        return;

      const ioHandler = (entries: any[]) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // entry.target.className = 'semaphore-logo semaphore-logo-medium sepia';
            entry.target.style.transition = '';
          } else {
            // entry.target.class = "";
            // entry.target.className = [];
            entry.target.style.transition = 'none';
          }
        });
      };

      const io = new IntersectionObserver(ioHandler);

      ioRef.current = io;
      ioRef.current.observe(animatedElementRef.current);

      ioHandler(io.takeRecords());

      return () => {
        ioRef.current.disconnect();
        ioRef.current = undefined;
      }
    }, [animatedElementRef])

    const cols = [
      <Col key={14} xl={8} lg={16} sm={24} xs={24} style={{ alignSelf: "center", padding: "24px 0" }}>
        {content.map(bc => subBlock(bc))}
      </Col>,
      <Col key={13} xl={16} lg={8} sm={24} xs={24} style={{ alignSelf: "center", padding: resp?.xxl ? 200 : resp?.xl ? 130 : 30 }}>
        <div style={{ textAlign: "right", maxWidth: 600, marginLeft: "auto", marginRight: "auto" }} ref={animatedElementRef} className='semaphore-logo semaphore-logo-medium sepia'>
          {imgr({ style: { borderRadius: 400 } })}
        </div>
      </Col>
    ];


    // if (rtl || !(resp?.xl || resp?.xxl)) cols.reverse(); // <-- this broke ssr!
    return <div className={`soft-vertical-background ${rtl ? "responsive-reverse-block" : ""}`}>
      <Row align="top" className='responsive-reverse-block sides-padding'>
        {cols}
      </Row>
      {extra}
    </div>

  }

  const videoRef = useRef<any>()
  const [videoOpen, setVideoOpen] = useState(false);
  useEffect(() => {
    // videoRef.current.play();

  }, [videoOpen])

  const rootRef = useRef<any>();
  const _size = useSize(rootRef);
  const size = { ..._size, width: (_size?.width || 0) - 40 }
  // const videoSize = size.width

  // const img = useMemo(() => `/semaphore${Math.round(Math.random() * 4) + 1}.jpeg`, [])
  return <div className="">

<Head key="metakeywords">
            <title>ForumScanner</title>
            <meta name="keywords" content={"blah"}></meta>
            <meta name="description" content={`WebSemaphore is a cloud-native, serverless concurrency management solution. It provides seamless coordination and control
for API communication in modern, highly distributed cloud environments.`}></meta>
            <script type="text/javascript" src="//cdnjs.cloudflare.com/ajax/libs/dygraph/2.2.1/dygraph.min.js"></script>
            <link rel="stylesheet" type="text/css" href="//cdnjs.cloudflare.com/ajax/libs/dygraph/2.2.1/dygraph.min.css" />
            <script type="module" src="https://unpkg.com/x-frame-bypass"></script>

        </Head>

    {videoOpen ? <Modal cancelButtonProps={{ style: { display: "none" } }} okText="Close" width={"100%"} onCancel={() => setVideoOpen(false)} onOk={() => setVideoOpen(false)} bodyStyle={{ background: "transparent", height: "85svh" }} style={{ top: "1svh", background: "transparent", }} open={videoOpen}>
      {/* @ts-ignore */}
      <iframe frameBorder="0"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/U4cA7tPy-V0?si=SaWMM4_I4axKL1wP?autoplay=1"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </Modal> : <></>}

    {WITH_LANES && <canvas style={{ filter: "xsepia(.8)", display: "none" }} ref={laneCanvasRef as any} />}
    <div className="landing-home">
      {/* <Card style={{ position: "relative" }}> */}
      <div ref={laneContainers.default as any} />
      <div className="hero-container soft-vertical-background">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='landing-content-width'>
          <div className=''>
            <Row gutter={48} align={"top"} className="hero">
              <Col key={"11"} lg={9} style={{ alignSelf: "center" }} className="hero-text-container">
                <h1 className='hero-main'>Follow forums like email</h1>
                <div className='hero-explainer'>
                  Capture leads when they are most interested.
                  ForumScanner is watching industry and product community conversations for you.
                </div>
                <ActionButtonsBlock />
                <br />
                <br />
              </Col>
              <Col key={"12"} lg={15} style={{ justifyContent: "end", display: "flex", padding: "0 24px 60px 24px" }}>
                {/* <Affix offsetTop={104}> */}
                <div>
                  <div className='semaphore-logo'>
                    <div className='semaphore-logo-comp'>
                    </div>
                    {useRandomImage({ className: "xaffix-hidden sepia", style: { maxWidth: 600, borderRadius: 400 } })}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>


      </div>



      <div  ref={rootRef} style={{ marginBottom: 50 }} className='sides-padding soft-vertical-background' >
        <div style={{}}>
          {/* {size.width} */}
          {/* @ts-ignore */}
          <iframe frameborder="0"
            width={size?.width}
            height={size?.width / 1.77777778}
            src="https://www.youtube.com/embed/U4cA7tPy-V0?si=SaWMM4_I4axKL1wP"
            title="YouTube video player"

            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>

          {/* <Button onClick={() => setVideoOpen(true)} style={{ fontSize: 50, height: 130, padding: 20 }}>
            Play Video&nbsp;
            <PlayCircleTwoTone />
          </Button> */}
        </div>
      </div>

      <div className="transparent-block short" />

      {/* <div style={{ marginBottom: 50 }} className='sides-padding soft-vertical-background' >
        <RateAnimationDefault />
      </div> */}
      <div className="transparent-block short" />

      <Row justify={"space-around"} className='sides-padding soft-vertical-background' style={{ textAlign: "center" }}>
        <div>
          <h2>Learn more based on your role profile:</h2>
          <IntroNav direction="horizontal" invertColors={true} />
        </div>
      </Row>

      <div ref={laneContainers.front as any} className="" />



      <div className="transparent-block" />


      {Block([
        {
          title: "Optimal resource utilization and reliability",
          text: "Explore the power of WebSemaphore to enhance the performance and reliability of your applications. With its simple API and cloud-native architecture, WebSemaphore provides a robust and scalable backbone for your solution."
        },
        {
          title: "Coordinate concurrent ditributed flows",
          text: "WebSemaphore provides a powerful and scalable solution for implementing distributed semaphores in multi-cloud applications, ensuring safe concurrent operations and seamless coordination of processes."
        },
        {
          title: "Cloud-native, developer-friendly",
          text: "WebSemaphore offers a simple API that can be easily integrated into your codebase while leveraging the scalability and reliability of cloud."
        }

      ])}


      <div ref={laneContainers.top as any} className="transparent-block" />

      <br />
      <br />
      <Text>

      </Text>
      <br />
      <br />

      {Block([
        {
          title: "Easy to Use",
          text: `
          WebSemaphore is designed to be user-friendly with a simple and intuitive interface,
          allowing developers to implement semaphores and manage access to shared resources with ease, without the need
          for complex custom development.
          `
        },
        {
          title: "Scalable and Cloud-Native",
          text: "Built on cloud-native architecture, WebSemaphore is designed to scale horizontally and seamlessly integrate with modern cloud-based applications. It provides the reliability and performance required to handle high levels of traffic in distributed environments."
        },
        {
          title: "Cost- and Resource- Efficient",
          text: "WebSemaphore helps you optimize resource utilization, reducing unnecessary costs associated with over-provisioning or contention for shared resources. You only pay for what you use, making it a cost-effective solution for your distributed applications."
        }

      ], true)}

      <div ref={laneContainers.back as any} className="transparent-block" />

      {
        Block([
          { title: "Get started", text: "Sign up and get up and running in no time", path: "/auth", icon: <RocketOutlined style={{ fontSize: 48 }} /> }, //, text: "Sign up and get up to speed in only a few minutes"
          { title: "Docs", path: "/docs/v1", text: "Check details, code examples and implementation scenarios", icon: <DesktopOutlined style={{ fontSize: 48 }} /> }, // text: "Check details, code examples and implementation scenarios" },
          // { title: "Live Demo", path: "/contact", icon: <UnorderedListOutlined style={{ fontSize: 48 }} /> },
          // { title: "Roadmap", path: "/roadmap", icon: <RocketOutlined style={{ fontSize: 48 }} /> },
          // { title: "Case Studies", path: "/case-studies", icon: <BookOutlined style={{ fontSize: 48 }} /> },
          { title: "Pricing", path: "/pricing", text: "Check our plans", icon: <CreditCardOutlined style={{ fontSize: 48 }} /> },
        ],
          false,
          <>

          </>
        )
      }

      {/* <Row justify={"space-around"} style={{ margin: "100px 0 100px" }}>
        <ActionButtonsBlock />
      </Row> */}
      <Row justify={"space-around"} className='sides-padding soft-vertical-background' style={{ padding: "100px 0 100px", textAlign: "center" }}>
        <div>
          <h2>Learn more based on your role profile:</h2>
          <IntroNav direction="horizontal" invertColors={true} />
        </div>
      </Row>

      <div ref={laneContainers.frontLeft as any} className="transparent-block short"></div>

    </div>
  </div >;
};

export default Page;